import React from "react";
import Button from "../button";
import smiling from "../../styles/img/pages/home/smiling.jpg"

const HeroSection = ({ data }) => {
    const { heroTitle1, heroTitle2, heroActionTitle, heroActionPath } = data

    return <section className="py-3 py-md-5 hero">
        <div className="container">
            <div className="row py-3 py-md-5 g-5 flex-lg-row flex-column-reverse align-items-center">
                <div className="col-12 col-lg-6 text-center text-lg-start">
                    <h1 className="display-4">
                        <span>{heroTitle1}</span>
                        <br />
                        <span className={`text-primary`}>{heroTitle2}</span>
                    </h1>
                    <Button type="link" href={heroActionPath} size="large" className="btn-brand-cta-1">
                        {heroActionTitle}
                    </Button>
                </div>
                <div className="col-12 col-lg-5 offset-lg-1">
                    <div className="rounded overflow-hidden shadow">
                        <img
                            src={smiling}
                            className="img-fluid w-100"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

export default HeroSection;