import React from "react";
import SlickSlider from "../slick-slider-new";
import Button from "../button";
import { StaticImage } from "gatsby-plugin-image";
import amazon from '../../styles/img/pages/home/amazon.png';
import microsoft from '../../styles/img/pages/home/microsoft.png';
import ingram from '../../styles/img/pages/home/ingram.png';
import { CaseStudyCard } from "../case-study-card";
import LocalizationService from "../../services/localization-service";

const CaseStudiesSection = ({ caseStudies, settings, data, caseStudyResourceSet }) => {
    const { resources } = caseStudyResourceSet;
    const localized = new LocalizationService(resources)
    const actionLabel = localized.getValue(`caseStudies.buttons.readMore`)

    const {
        caseStudiesTitle,
        caseStudiesCardTitle,
        caseStudiesCardDescription1,
        caseStudiesCardDescription2,
        caseStudiesCardDescription3,
        caseStudiesCardActionTitle,
        caseStudiesCardActionPath,
        caseStudiesQuote,
        caseStudiesQuoteTitle,
        caseStudiesQuoteAuthor,
        caseStudiesQuoteAuthorRole,
        caseStudiesPartnersTitle1,
        caseStudiesPartnersTitle2,
        caseStudiesPartnersDescription,
        caseStudiesPartnersPromo1,
        caseStudiesPartnersPromo2,
        caseStudiesPartnersActionTitle,
        caseStudiesPartnersActionPath,
    } = data;

    return <section className="case-studies">
        <div className="bg-primary pt-md-5">
            <div className="container py-3 py-md-5 text-center text-white">
                <h2 className="mb-0 display-4">
                    {caseStudiesTitle}
                </h2>
            </div>
            <div className="pl-case-study">
                <div className="px-3 px-md-5 py-3 py-md-5 bg-white rounded container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h3>
                                {caseStudiesCardTitle}
                            </h3>
                            <p>
                                {caseStudiesCardDescription1}
                            </p>
                        </div>
                        <div className="col-12 col-md-6">
                            <p>
                                {caseStudiesCardDescription2}
                            </p>
                            <p>
                                {caseStudiesCardDescription3}
                            </p>
                            <div>
                                <a className="btn btn-link ps-0" href={caseStudiesCardActionPath}>
                                    {caseStudiesCardActionTitle}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-light py-3 py-md-5">
            <div className="container testimonial py-3">
                <div className="row g-5">
                    <div className="col-12 col-md-6">
                        <h4 className="fw-bold">
                            {`"${caseStudiesQuoteTitle}"`}
                        </h4>
                        <p>
                            {caseStudiesQuote}
                        </p>
                        <div className="profile-pic">
                            <StaticImage className="rounded-circle" src="../styles/img/pages/home/gopal-swamy.jpg" alt="Gopal Swamy" />
                        </div>
                        <span className="fw-bold"><span className="text-primary">{caseStudiesQuoteAuthor}</span>, {caseStudiesQuoteAuthorRole}</span>
                    </div>
                    <div className="col-12 col-md-5 offset-md-1">
                        <h4 className="fw-bold">
                            <span>{caseStudiesPartnersTitle1}</span>{' '}
                            <br />
                            <span className="text-primary">
                                {caseStudiesPartnersTitle2}
                            </span>
                        </h4>
                        <p>
                            {caseStudiesPartnersDescription}
                        </p>
                        <div className="row justify-content-center">
                            <div className="col-6">
                                <img
                                    src={ingram}
                                    className="img-fluid"
                                    alt="ingram"
                                />
                            </div>
                            <div className="col-6">
                                <img
                                    src={amazon}
                                    className="img-fluid"
                                    alt="amazon"
                                />
                            </div>
                            <div className="col-6">
                                <img
                                    src={microsoft}
                                    className="img-fluid"
                                    alt="microsoft"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-5">
                    <h3 className="fw-bold">
                        {caseStudiesPartnersPromo1}<br />
                        {caseStudiesPartnersPromo2}
                    </h3>
                    <div className="row align-items-stretch g-3 g-lg-5 d-none d-md-flex">
                        {caseStudies.nodes
                            .slice(0, 3)
                            .map(({ title, path, brandLogo }, index) => <CaseStudyCard key={index} title={title} id={path} size="small" logo={brandLogo} actionLabel={actionLabel} />)}
                    </div>
                    <div className="d-md-none">
                        <SlickSlider customSettings={settings}>
                            {caseStudies.nodes
                                .slice(0, 3)
                                .map(({ title, path }, index) => <CaseStudyCard key={index} title={title} id={path} size="small" actionLabel={actionLabel} />)}
                        </SlickSlider>
                    </div>
                    <div className="text-center mt-5">
                        <Button
                            type="link"
                            size="large"
                            href={caseStudiesPartnersActionPath}
                            className="btn btn-primary"
                        >
                            {caseStudiesPartnersActionTitle}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default CaseStudiesSection;