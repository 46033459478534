import React from 'react';
import { graphql } from 'gatsby';
import Footer from '../components/footer';
import Layout from '../components/layout';
import HelmetEx from '../components/helmet-ex';
import LocalizationService from '../services/localization-service';
import { ResourceUtils } from '../utils';
import HeroSection from '../components/home/hero-section';
import LogosSection from '../components/home/logos-section';
import ServicesSection from '../components/home/services-section';
import NybcCaseStudySection from '../components/home/nybc-case-study-section';
import ModernAppsSection from '../components/home/modern-apps-section';
import SolutionsSection from '../components/home/solutions-section';
import FullServiceSection from '../components/home/full-services-section';
import DifferentiatorSection from '../components/home/differentiator-section';
import CaseStudiesSection from '../components/home/case-studies-section';
import ContactUs from '../components/home/contact-us-section'

const settings = {
    responsive: [
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 1
            }
        }
    ],
    dots: true,
    autoplaySpeed: 10000
};

const PAGE_MICROCOPY_MAP = {
    heroTitle1: 'hero_section.title_1',
    heroTitle2: 'hero_section.title_2',
    heroActionTitle: 'hero_section.action_title',
    heroActionPath: 'hero_section.action_path',
    servicesTitle: 'services_section.title',
    servicesStartTitle: 'services_section.service_start.title',
    servicesStartDescription: 'services_section.service_start.description',
    servicesStartActionTitle: 'services_section.service_start.action_title',
    servicesStartActionPath: 'services_section.service_start.action_path',
    servicesModernizeTitle: 'services_section.service_modernize.title',
    servicesModernizeDescription: 'services_section.service_modernize.description',
    servicesModernizeActionTitle: 'services_section.service_modernize.action_title',
    servicesModernizeActionPath: 'services_section.service_modernize.action_path',
    servicesScaleTitle: 'services_section.service_scale.title',
    servicesScaleDescription: 'services_section.service_scale.description',
    servicesScaleActionTitle: 'services_section.service_scale.action_title',
    servicesScaleActionPath: 'services_section.service_scale.action_path',
    nybcTitle: 'nybc_section.title',
    nybcItem1: 'nybc_section.item_1',
    nybcItem2: 'nybc_section.item_2',
    nybcItem3: 'nybc_section.item_3',
    nybcItem4: 'nybc_section.item_4',
    nybcItem5: 'nybc_section.item_5',
    nybcActionTitle: 'nybc_section.action_title',
    nybcActionPath: 'nybc_section.action_path',
    nybcAuthorName: 'nybc_section.author_name',
    nybcAuthorRole: 'nybc_section.author_role',
    nybcAuthorQuote: 'nybc_section.author_quote',
    modernAppsTitle: 'modern_apps_section.title',
    modernAppsActionTitle: 'modern_apps_section.action_title',
    modernAppsActionPath: 'modern_apps_section.action_path',
    modernAppsCloudNative: 'modern_apps_section.cloud_native',
    modernAppsCloudNativeTitle: 'modern_apps_section.cloud_native.title',
    modernAppsCloudNativeDescription: 'modern_apps_section.cloud_native.description',
    modernAppsConnected: 'modern_apps_section.connected',
    modernAppsConnectedTitle: 'modern_apps_section.connected.title',
    modernAppsConnectedDescription: 'modern_apps_section.connected.description',
    modernAppsWellArchitected: 'modern_apps_section.well-architected',
    modernAppsWellArchitectedTitle: 'modern_apps_section.well-architected.title',
    modernAppsWellArchitectedDescription: 'modern_apps_section.well-architected.description',
    modernAppsChannelAgnostic: 'modern_apps_section.channel-agnostic',
    modernAppsChannelAgnosticTitle: 'modern_apps_section.channel-agnostic.title',
    modernAppsChannelAgnosticDescription: 'modern_apps_section.channel-agnostic.description',
    modernAppsWeb: 'modern_apps_section.web',
    modernAppsMobile: 'modern_apps_section.mobile',
    modernAppsWearables: 'modern_apps_section.wearables',
    modernAppsAPIs: 'modern_apps_section.apis',
    solutionsTitle: 'solutions_section.title',
    solutionsDescription: 'solutions_section.description',
    solutionsPaymentIntegration: 'solutions_section.payment_integration',
    solutionsBusinessPortals: 'solutions_section.business_portals',
    solutionsVideoStreaming: 'solutions_section.video_streaming',
    solutionsConsumerApps: 'solutions_section.consumer_apps',
    solutionsAuthorQuote: 'solutions_section.author_quote',
    solutionsAuthorQuoteExpanded: 'solutions_section.author_quote_expanded',
    solutionsAuthorName: 'solutions_section.author_name',
    solutionsAuthorRole: 'solutions_section.author_role',
    fullServicesTitle1: 'full_services_section.title1',
    fullServicesTitle2: 'full_services_section.title2',
    fullServicesAdvise: 'full_services_section.advise',
    fullServicesAdviseIdeate: 'full_services_section.advise.ideate',
    fullServicesAdviseDefine: 'full_services_section.advise.define',
    fullServicesAdvisePlan: 'full_services_section.advise.plan',
    fullServicesBuild: 'full_services_section.build',
    fullServicesBuildDeploy: 'full_services_section.build.deploy',
    fullServicesBuildDevelop: 'full_services_section.build.develop',
    fullServicesBuildDesign: 'full_services_section.build.design',
    fullServicesSupport: 'full_services_section.support',
    fullServicesSupportMarket: 'full_services_section.support.market',
    fullServicesSupportValidate: 'full_services_section.support.validate',
    fullServicesSupportTest: 'full_services_section.support.test',
    differentiatorTitle1: 'differentiator_section.title1',
    differentiatorTitle2: 'differentiator_section.title2',
    differentiatorActionTitle: 'differentiator_section.action_title',
    differentiatorActionPath: 'differentiator_section.action_path',
    differentiatorPeople: 'differentiator_section.people',
    differentiatorPeopleTitle: 'differentiator_section.people.title',
    differentiatorPeopleDescription: 'differentiator_section.people.description',
    differentiatorTechnology: 'differentiator_section.technology',
    differentiatorTechnologyTitle: 'differentiator_section.technology.title',
    differentiatorTechnologyDescription: 'differentiator_section.technology.description',
    differentiatorProcess: 'differentiator_section.process',
    differentiatorProcessTitle: 'differentiator_section.process.title',
    differentiatorProcessDescription: 'differentiator_section.process.description',
    caseStudiesTitle: 'case_studies_section.title',
    caseStudiesCardTitle: 'case_studies_section.card.title',
    caseStudiesCardDescription1: 'case_studies_section.card.description_1',
    caseStudiesCardDescription2: 'case_studies_section.card.description_2',
    caseStudiesCardDescription3: 'case_studies_section.card.description_3',
    caseStudiesCardActionTitle: 'case_studies_section.card.action_title',
    caseStudiesCardActionPath: 'case_studies_section.card.action_path',
    caseStudiesQuote: 'case_studies_section.quote',
    caseStudiesQuoteTitle: 'case_studies_section.quote.title',
    caseStudiesQuoteAuthor: 'case_studies_section.quote.author',
    caseStudiesQuoteAuthorRole: 'case_studies_section.quote.author_role',
    caseStudiesPartnersTitle1: 'case_studies_section.partners.title1',
    caseStudiesPartnersTitle2: 'case_studies_section.partners.title2',
    caseStudiesPartnersDescription: 'case_studies_section.partners.description',
    caseStudiesPartnersPromo1: 'case_studies_section.partners.promo1',
    caseStudiesPartnersPromo2: 'case_studies_section.partners.promo2',
    caseStudiesPartnersActionTitle: 'case_studies_section.partners.action_title',
    caseStudiesPartnersActionPath: 'case_studies_section.partners.action_path',
    contactUsTitle: 'contact_us_section.title',
    contactUsActionTitle: 'contact_us_section.action_title',
    contactUsActionPath: 'contact_us_section.action_path',
};

const Home = ({ data, localeKey }) => {
    const { allContentfulCaseStudy: caseStudies, homePage, caseStudyResourceSet } = data;
    const { edges: homePageEdges } = homePage
    const locale = homePageEdges.find(locale => locale.node.node_locale.startsWith(localeKey))
    const keyPrefix = `home_page`
    const localized = new LocalizationService(locale.node.resources)
    const microcopy = ResourceUtils.getMicrocopy(localized, keyPrefix, PAGE_MICROCOPY_MAP)
    console.log(`microcopy`, microcopy);

    return <Layout>
        <div className="home-page">
            <HelmetEx>
                <div metadata="title">
                    Polymaths | Modern App Development
                </div>
                <div metadata="keywords">
                    Software, Development, AWS Consulting Partner, Polymaths, Microservice, Architecture, Serverless, JS, Node Js,
                    Lambda, Cloud Native Application, Agile, Scalability,
                    Wireframes, Solution
                </div>
                <div metadata="description">
                    Polymaths is a NYC-based software development firm
                    focused on Modern App Development (Cloud Native /
                    Serverless).
                </div>
            </HelmetEx>

            <HeroSection data={microcopy} />
            <LogosSection />
            <ServicesSection settings={settings} data={microcopy} />
            <NybcCaseStudySection data={microcopy} />
            <ModernAppsSection settings={settings} data={microcopy} />
            <SolutionsSection data={microcopy} />
            <FullServiceSection data={microcopy} />
            <DifferentiatorSection settings={settings} data={microcopy} />
            <CaseStudiesSection caseStudies={caseStudies} caseStudyResourceSet={caseStudyResourceSet} data={microcopy} />
            <ContactUs data={microcopy} />
        </div>
        <Footer />
    </Layout>;
};

export default Home;