import React from "react";
import LogoSlider from "../logo-slider";

const LogosSection = () => <section className="slider-section bg-light py-5 order-1 order-lg-1">
    <div className="container">
        <div className="d-none d-lg-block">
            <LogoSlider slidesToShow={5} slidesToScroll={2} />
        </div>
        <div className="d-none d-lg-none d-md-block">
            <LogoSlider slidesToShow={2} slidesToScroll={2} />
        </div>
        <div className="d-block d-md-none">
            <LogoSlider slidesToShow={2} slidesToScroll={1} />
        </div>
    </div>
</section>;

export default LogosSection