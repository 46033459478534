import React from "react";
import office from '../../styles/img/pages/home/office.jpeg';
import { StaticImage } from "gatsby-plugin-image";

const SolutionsSection = ({ data }) => {
    const {
        solutionsTitle,
        solutionsDescription,
        solutionsPaymentIntegration,
        solutionsBusinessPortals,
        solutionsVideoStreaming,
        solutionsConsumerApps,
        solutionsAuthorQuote,
        solutionsAuthorQuoteExpanded,
        solutionsAuthorName,
        solutionsAuthorRole
    } = data;

    return <section className="assets">
        <div className="container py-3 py-md-5">
            <div className="row g-5 align-items-center justify-content-center py-3 py-md-5">
                <div className="col-12 col-md-5">
                    <div className="rounded overflow-hidden">
                        <img
                            src={office}
                            className="rounded img-fluid"
                        />
                    </div>
                </div>
                <div className="col-12 offset-md-1 col-md-6">
                    <h3 className="mt-3 display-5">
                        {solutionsTitle}
                    </h3>
                    <p>
                        {solutionsDescription}
                    </p>
                    <div className="row g-4 mt-3">
                        <div className="col-6 h1 lh-1">
                            <a className="text-decoration-none text-dark" href="/solutions/payment-integration">
                                {solutionsPaymentIntegration}
                            </a>
                        </div>
                        <div className="col-6 h1 lh-1">
                            <a className="text-decoration-none text-dark" href="/solutions/business-portals">
                                {solutionsBusinessPortals}
                            </a>
                        </div>
                        <div className="col-6 h1 lh-1">
                            <a className="text-decoration-none text-dark" href="/solutions/video-streaming">
                                {solutionsVideoStreaming}
                            </a>
                        </div>
                        <div className="col-6 h1 lh-1">
                            {SolutionsSection}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-light">
            <div className="container">
                <div className="row g-3 gx-md-5 g-md-0 py-5">
                    <div className="col-12 col-md-6">
                        <strong className="display-5 text-center text-md-start">
                            {`"${solutionsAuthorQuote}"`}
                        </strong>
                    </div>
                    <div className="col-12 col-md-6">
                        <p>
                            {solutionsAuthorQuoteExpanded}
                        </p>
                        <div className="profile-pic">
                            <StaticImage className="rounded-circle" src="../styles/img/pages/home/jason-fan.jpg" alt="Jason fan" />
                        </div>
                        <strong>
                            <span className="text-primary fw-bold me-1">
                                {`${solutionsAuthorName},`}
                            </span>
                            <span className="fw-bold">
                                {solutionsAuthorRole}
                            </span>
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default SolutionsSection;