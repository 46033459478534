import React from "react";
import SlickSlider from "../slick-slider-new";

const ModernAppsSection = ({ settings, data }) => {
    const {
        modernAppsTitle,
        modernAppsActionTitle,
        modernAppsActionPath,
        modernAppsCloudNative,
        modernAppsCloudNativeTitle,
        modernAppsCloudNativeDescription,
        modernAppsConnected,
        modernAppsConnectedTitle,
        modernAppsConnectedDescription,
        modernAppsWellArchitected,
        modernAppsWellArchitectedTitle,
        modernAppsWellArchitectedDescription,
        modernAppsChannelAgnostic,
        modernAppsChannelAgnosticTitle,
        modernAppsChannelAgnosticDescription,
        modernAppsWeb,
        modernAppsMobile,
        modernAppsWearables,
        modernAppsAPIs
    } = data

    return <section className="modern-apps-section">
        <div className="bg-brand-primary-2 py-5 text-center text-white">
            <div className="container">
                <h2 className="mb-0 display-4">
                    {modernAppsTitle}
                </h2>
            </div>
        </div>
        <div className="bg-primary py-3 py-md-5 text-white">
            <div className="container py-3 py-md-5">
                <div className="row g-5 d-none d-md-flex">
                    <div className="col-12 align-items-start col-md-6 col-lg-4 d-flex flex-column justify-content-between">
                        <div>
                            <span className="lead">
                                {modernAppsCloudNative}
                            </span>
                            <h3>
                                {modernAppsCloudNativeTitle}
                            </h3>
                            <p>
                                {modernAppsCloudNativeDescription}
                            </p>
                        </div>
                        <a
                            href={modernAppsActionPath}
                            className="btn bg-white text-brand-primary-2"
                        >
                            {modernAppsActionTitle}
                        </a>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex flex-column align-items-start justify-content-between">
                        <div>
                            <span className="lead">
                                {modernAppsConnected}
                            </span>
                            <h3>
                                {modernAppsConnectedTitle}
                            </h3>
                            <p>
                                {modernAppsConnectedDescription}
                            </p>
                        </div>
                        <a
                            href={modernAppsActionPath}
                            className="btn bg-white text-brand-primary-2"
                        >
                            {modernAppsActionTitle}
                        </a>
                    </div>
                    <div className="col-12 align-items-start col-md-6 col-lg-4 d-flex flex-column justify-content-between">
                        <div>
                            <span className="lead">
                                {modernAppsWellArchitected}
                            </span>
                            <h3>
                                {modernAppsWellArchitectedTitle}
                            </h3>
                            <p>
                                {modernAppsWellArchitectedDescription}
                            </p>
                        </div>
                        <a
                            href={modernAppsActionPath}
                            className="btn bg-white text-brand-primary-2"
                        >
                            {modernAppsActionTitle}
                        </a>
                    </div>
                    <div className="col-12 align-items-start col-md-6 col-lg-4 d-flex flex-column justify-content-between">
                        <span className="lead">
                            {modernAppsChannelAgnostic}
                        </span>
                        <h4>{modernAppsChannelAgnosticTitle}</h4>
                        <p>
                            {modernAppsChannelAgnosticDescription}
                        </p>
                        <a
                            href={modernAppsActionPath}
                            className="btn bg-white text-brand-primary-2"
                        >
                            {modernAppsActionTitle}
                        </a>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="border-bottom pb-2">
                            {modernAppsWeb}
                        </div>
                        <div className="border-bottom pb-2">
                            {modernAppsMobile}
                        </div>
                        <div className="border-bottom pb-2">
                            {modernAppsWearables}
                        </div>
                        <div className="border-bottom pb-2">
                            {modernAppsAPIs}
                        </div>
                    </div>
                </div>
                <div className="d-md-none">
                    <SlickSlider customSettings={settings}>
                        <div>
                            <span className="lead">
                                {modernAppsCloudNative}
                            </span>
                            <h3>
                                {modernAppsCloudNativeTitle}
                            </h3>
                            <p>
                                {modernAppsCloudNativeDescription}
                            </p>
                            <a
                                href={modernAppsActionPath}
                                className="btn bg-white text-brand-primary-2"
                            >
                                {modernAppsActionTitle}
                            </a>
                        </div>
                        <div>
                            <span className="lead">
                                {modernAppsConnected}
                            </span>
                            <h3>
                                {modernAppsConnectedTitle}
                            </h3>
                            <p>
                                {modernAppsConnectedDescription}
                            </p>
                            <a
                                href={modernAppsActionPath}
                                className="btn bg-white text-brand-primary-2"
                            >
                                {modernAppsActionTitle}
                            </a>
                        </div>
                        <div>
                            <span className="lead">
                                {modernAppsWellArchitected}
                            </span>
                            <h3>
                                {modernAppsWellArchitectedTitle}
                            </h3>
                            <p>
                                {modernAppsWellArchitectedDescription}
                            </p>
                            <a
                                href={modernAppsActionPath}
                                className="btn bg-white text-brand-primary-2"
                            >
                                {modernAppsActionTitle}
                            </a>
                        </div>
                        <div>
                            <span className="lead">
                                {modernAppsChannelAgnostic}
                            </span>
                            <h4>
                                {modernAppsChannelAgnosticTitle}
                            </h4>
                            <p>
                                {modernAppsChannelAgnosticDescription}
                            </p>
                            <a
                                href={modernAppsActionPath}
                                className="btn bg-white text-brand-primary-2"
                            >
                                {modernAppsActionTitle}
                            </a>
                        </div>
                        <div>
                            <div className="border-bottom pb-2">
                                {modernAppsWeb}
                            </div>
                            <div className="border-bottom pb-2">
                                {modernAppsMobile}
                            </div>
                            <div className="border-bottom pb-2">
                                {modernAppsWearables}
                            </div>
                            <div className="border-bottom pb-2">
                                {modernAppsAPIs}
                            </div>
                        </div>
                    </SlickSlider>
                </div>
            </div>
        </div>
    </section>
};

export default ModernAppsSection;