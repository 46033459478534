import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../button";

const NybcCaseStudySection = ({ data }) => {
    const {
        nybcTitle,
        nybcItem1,
        nybcItem2,
        nybcItem3,
        nybcItem4,
        nybcItem5,
        nybcActionTitle,
        nybcActionPath,
        nybcAuthorName,
        nybcAuthorRole,
        nybcAuthorQuote,
    } = data;

    return <section className="pt-3 pt-md-5 new-blood-center">
        <div className="container py-3 py-md-5">
            <div className="row g-5 align-items-center justify-content-center">
                <div className="col-12 col-lg-6">
                    <div className="rounded overflow-hidden">
                        <StaticImage
                            src="../../styles/img/pages/home/nyc-blood-center.png"
                            className="rounded img-fluid"
                        />
                    </div>
                </div>
                <div className="col-12 offset-lg-1 col-lg-5">
                    <h3 className="display-5">
                        {nybcTitle}
                    </h3>
                    <ul className="ps-3">
                        <li>
                            {nybcItem1}
                        </li>
                        <li>
                            {nybcItem2}
                        </li>
                        <li>
                            {nybcItem3}
                        </li>
                        <li>
                            {nybcItem4}
                        </li>
                        <li>
                            {nybcItem5}
                        </li>
                    </ul>
                    <Button
                        type="link"
                        href={nybcActionPath}
                        className="btn btn-primary"
                    >
                        {nybcActionTitle}
                    </Button>
                </div>
            </div>
            <div className="col-12 col-md-6 pt-4">
                <p>
                    {`"${nybcAuthorQuote}"`}
                </p>
                <div className="profile-pic">
                    <StaticImage className="rounded-circle" src="../../styles/img/testimonials/michele-scaggiante.jpg" alt={nybcAuthorName} />
                </div>
                <span className="text-primary d-block fw-bold">
                    {nybcAuthorName}
                </span>
                <span className="fw-bold">{nybcAuthorRole}</span>
            </div>
        </div>
    </section>
};

export default NybcCaseStudySection;