import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const FullServiceSection = ({ data }) => {
    const {
        fullServicesTitle1,
        fullServicesTitle2,
        fullServicesAdvise,
        fullServicesAdviseIdeate,
        fullServicesAdviseDefine,
        fullServicesAdvisePlan,
        fullServicesBuild,
        fullServicesBuildDeploy,
        fullServicesBuildDevelop,
        fullServicesBuildDesign,
        fullServicesSupport,
        fullServicesSupportMarket,
        fullServicesSupportValidate,
        fullServicesSupportTest,
    } = data;

    return <section className="idea-to-market">
        <div className="bg-primary py-3 py-md-5 text-white">
            <div className="container text-center text-md-start py-3 py-md-5">
                <h3 className="mb-0 display-5 fw-bold">
                    {fullServicesTitle1}
                    <strong className="d-md-block ms-1 ms-md-0">
                        {fullServicesTitle2}
                    </strong>
                </h3>
            </div>
        </div>
        <div className="bg-light py-3 py-md-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-12 col-md-4">
                        <div className="rounded p-5 card border-0 flex-row flex-md-column">
                            <div>
                                <StaticImage
                                    src="../../styles/img/icons/hands.svg"
                                    alt="hands"
                                />
                                <h4 className="my-2">{fullServicesAdvise}</h4>
                            </div>
                            <div className="ms-3 ms-md-0">
                                <ul className="m-0 ps-3 p-0">
                                    <li>{fullServicesAdviseIdeate}</li>
                                    <li>{fullServicesAdviseDefine}</li>
                                    <li>{fullServicesAdvisePlan}</li>
                                </ul>
                            </div>
                            <span className="arrow d-none d-md-inline text-black bg-light rounded-circle p-4 d-flex justify-content-center align-items-center">
                                <i className="fa fa-chevron-right fa-3x"></i>
                            </span>
                            <span className="arrow d-inline d-md-none text-black bg-light rounded-circle p-4 d-flex justify-content-center align-items-center">
                                <i className="fa fa-chevron-down fa-3x"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="rounded p-5 card border-0 flex-row flex-md-column">
                            <div>
                                <StaticImage
                                    src={
                                        '../../styles/img/icons/computer.svg'
                                    }
                                    alt="computer"
                                />
                                <h4 className="my-2">{fullServicesBuild}</h4>
                            </div>
                            <div className="ms-3 ms-md-0">
                                <ul className="m-0 ps-3 p-0">
                                    <li>{fullServicesBuildDeploy}</li>
                                    <li>{fullServicesBuildDevelop}</li>
                                    <li>{fullServicesBuildDesign}</li>
                                </ul>
                            </div>
                            <span className="arrow d-none d-md-inline text-black bg-light rounded-circle p-4 d-flex justify-content-center align-items-center">
                                <i className="fa fa-chevron-right fa-3x"></i>
                            </span>
                            <span className="arrow d-inline d-md-none text-black bg-light rounded-circle p-4 d-flex justify-content-center align-items-center">
                                <i className="fa fa-chevron-down fa-3x"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="rounded p-5 card border-0 flex-row flex-md-column">
                            <div>
                                <StaticImage
                                    src={
                                        '../../styles/img/icons/graph.svg'
                                    }
                                    alt="graph"
                                />
                                <h4 className="my-2">
                                    {fullServicesSupport}
                                </h4>
                            </div>
                            <div className="ms-3 ms-md-0">
                                <ul className="m-0 ps-3 p-0">
                                    <li>{fullServicesSupportMarket}</li>
                                    <li>{fullServicesSupportValidate}</li>
                                    <li>{fullServicesSupportTest}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default FullServiceSection;