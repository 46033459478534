import React from "react";
import Button from "../button";

const ContactUs = ({ data }) => {
    const {
        contactUsTitle,
        contactUsActionTitle,
        contactUsActionPath,
    } = data;

    return <section>
        <div className="bg-primary py-3 py-md-5 text-white">
            <div className="container text-center">
                <h2 className="fw-bold display-5">
                    {contactUsTitle}
                </h2>
                <Button
                    size="medium"
                    type="polymath-link"
                    to={contactUsActionPath}
                    className="btn bg-white text-brand-primary-2"
                >
                    {contactUsActionTitle}
                </Button>
            </div>
        </div>
    </section>
};

export default ContactUs;