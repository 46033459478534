import React from "react"
import startIcon from '../../styles/img/pages/home/start_icon.svg';
import modernizeIcon from '../../styles/img/pages/home/modernize_icon.svg';
import scaleIcon from '../../styles/img/pages/home/scale_icon.svg';
import Button from "../button";
import SlickSlider from "../slick-slider-new";

const ServicesSection = ({ settings, data }) => {
    const {
        servicesTitle,
        servicesStartTitle,
        servicesStartDescription,
        servicesStartActionTitle,
        servicesStartActionPath,
        servicesModernizeTitle,
        servicesModernizeDescription,
        servicesModernizeActionTitle,
        servicesModernizeActionPath,
        servicesScaleTitle,
        servicesScaleDescription,
        servicesScaleActionTitle,
        servicesScaleActionPath,
    } = data

    const callToActionClass = 'btn-white text-brand-primary-2';
    return <section className="current-challenge">
        <div className="bg-brand-primary-2 py-5 text-white">
            <div className="container text-center">
                <h2 className="mb-0 display-5">
                    {servicesTitle}
                </h2>
            </div>
        </div>
        <div className="bg-brand-primary-1 py-3 py-md-5 text-white services">
            <div className="container py-3 py-md-5">
                <div className="row g-5 justify-content-center d-none d-md-flex text-center">
                    <div className="col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-start">
                        <div>
                            <img src={startIcon} className="img-fluid" alt="START" />
                        </div>
                        <h3 className="py-3 mb-0">{servicesStartTitle}</h3>
                        <p>
                            {`"${servicesStartDescription}"`}
                        </p>
                        <div className="d-flex flex-column justify-content-end h-100">
                            <Button
                                size="large"
                                type="polymath-link"
                                href={servicesStartActionPath}
                                className={`${callToActionClass}`}
                            >
                                {servicesStartActionTitle}
                            </Button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-start">
                        <div>
                            <img src={modernizeIcon} className="img-fluid" alt="modernization" />
                        </div>
                        <h3 className="py-3 mb-0">
                            {servicesModernizeTitle}
                        </h3>
                        <p>
                            {`"${servicesModernizeDescription}"`}
                        </p>
                        <div className="d-flex flex-column justify-content-end h-100">
                            <Button
                                size="large"
                                type="polymath-link"
                                href={servicesModernizeActionPath}
                                className={`${callToActionClass}`}
                            >
                                {servicesModernizeActionTitle}
                            </Button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-start">
                        <div>
                            <img src={scaleIcon} className="img-fluid" alt="Scale your development" />
                        </div>
                        <h3 className="py-3 mb-0">
                            {servicesScaleTitle}
                        </h3>
                        <p>
                            {`"${servicesScaleDescription}"`}
                        </p>
                        <div className="d-flex flex-column justify-content-end h-100">
                            <Button
                                size="large"
                                type="polymath-link"
                                href={servicesScaleActionPath}
                                className={`${callToActionClass}`}
                            >
                                {servicesScaleActionTitle}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="d-md-none">
                    <SlickSlider customSettings={settings}>
                        <div className="text-center">
                            <div className="overflow-hidden">
                                <img src={startIcon} className="img-fluid m-auto" alt="START" />
                            </div>
                            <h3 className="py-3 mb-0">{servicesStartTitle}</h3>
                            <p>
                                {`"${servicesStartDescription}"`}
                            </p>
                            <Button
                                type="polymath-link"
                                href={servicesStartActionPath}
                                className={`${callToActionClass}`}
                            >
                                {servicesStartActionTitle}
                            </Button>
                        </div>
                        <div className="text-center">
                            <div className="overflow-hidden">
                                <img src={modernizeIcon} className="img-fluid m-auto" alt="modernization" />
                            </div>
                            <h3 className="py-3 mb-0">
                                {servicesModernizeTitle}
                            </h3>
                            <p>
                                {`"${servicesModernizeDescription}"`}
                            </p>
                            <Button
                                type="polymath-link"
                                href={servicesModernizeActionPath}
                                className={`${callToActionClass}`}
                            >
                                {servicesModernizeActionTitle}
                            </Button>
                        </div>
                        <div className="text-center">
                            <div className="overflow-hidden">
                                <img src={scaleIcon} className="img-fluid m-auto" alt="Scale your development" />
                            </div>
                            <h3 className="py-3 mb-0">
                                {servicesScaleTitle}
                            </h3>
                            <p>
                                {`"${servicesScaleDescription}"`}
                            </p>
                            <Button
                                type="polymath-link"
                                href={servicesScaleActionPath}
                                className={`${callToActionClass}`}
                            >
                                {servicesScaleActionTitle}
                            </Button>
                        </div>
                    </SlickSlider>
                </div>
            </div>
        </div>
    </section>;
};

export default ServicesSection