import React from "react";
import SlickSlider from "../slick-slider-new";
import Button from "../button";

const DifferentiatorSection = ({ settings, data }) => {

    const {
        differentiatorTitle1,
        differentiatorTitle2,
        differentiatorActionTitle,
        differentiatorActionPath,
        differentiatorPeople,
        differentiatorPeopleTitle,
        differentiatorPeopleDescription,
        differentiatorTechnology,
        differentiatorTechnologyTitle,
        differentiatorTechnologyDescription,
        differentiatorProcess,
        differentiatorProcessTitle,
        differentiatorProcessDescription,
    } = data;

    return <section className="py-3 py-md-5">
        <div className="container py-5">
            <div className="row g-5 justify-content-end">
                <div className="col-12 col-md-8">
                    <h3 className="display-5 fw-bold">
                        {differentiatorTitle1}
                        <span className="text-primary ms-2 ms-md-0 d-md-block">
                            {differentiatorTitle2}
                        </span>
                    </h3>
                    <div className="d-md-none pt-3 pb-5">
                        <SlickSlider customSettings={settings}>
                            <div>
                                <div className="text-primary mb-2">
                                    {differentiatorPeople}
                                </div>
                                <h4 className="fw-bold">
                                    {differentiatorPeopleTitle}
                                </h4>
                                <p>
                                    {differentiatorPeopleDescription}
                                </p>
                            </div>
                            <div>
                                <div className="text-primary mb-2">
                                    {differentiatorTechnology}
                                </div>
                                <h4 className="fw-bold">
                                    {differentiatorTechnologyTitle}
                                </h4>
                                <p>
                                    {differentiatorTechnologyDescription}
                                </p>
                            </div>
                            <div>
                                <div className="text-primary mb-2">
                                    {differentiatorProcess}
                                </div>
                                <h4 className="fw-bold">
                                    {differentiatorProcessTitle}
                                </h4>
                                <p>
                                    {differentiatorPeopleDescription}
                                </p>
                            </div>
                        </SlickSlider>
                    </div>
                    <Button
                        type="link"
                        size="large"
                        href={differentiatorActionPath}
                        className="btn btn-primary"
                        anchorClass="d-flex justify-content-center justify-content-md-start"
                    >
                        {differentiatorActionTitle}
                    </Button>
                </div>
                <div className="col-12 col-md-4 d-none d-md-block">
                    <div className="text-primary mb-2">
                        {differentiatorPeople}
                    </div>
                    <h4 className="fw-bold">
                        {differentiatorPeopleTitle}
                    </h4>
                    <p>
                        {differentiatorPeopleDescription}
                    </p>
                </div>
                <div className="col-12 col-md-4 d-none d-md-block">
                    <div className="text-primary mb-2">
                        {differentiatorTechnology}
                    </div>
                    <h4 className="fw-bold">
                        {differentiatorTechnologyTitle}
                    </h4>
                    <p>
                        {differentiatorTechnologyDescription}
                    </p>
                </div>
                <div className="col-12 col-md-4 d-none d-md-block">
                    <div className="text-primary mb-2">
                        {differentiatorProcess}
                    </div>
                    <h4 className="fw-bold">
                        {differentiatorProcessTitle}
                    </h4>
                    <p>
                        {differentiatorProcessDescription}
                    </p>
                </div>
            </div>
        </div>
    </section>
};

export default DifferentiatorSection;