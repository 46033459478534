import React from 'react';
import { graphql } from 'gatsby';
import Home from '../templates/Home';

const HomePage = ({ data, pageContext }) => {
  const { language } = pageContext;
  return <Home data={data} localeKey={language} />
}

export default HomePage;

export const query = graphql`
    query($languageRegEx: String!) {
        homePage: allContentfulResourceSet(
            filter: {name: {regex: "/^home_page/"}}
          ) {
            edges {
              node {
                name
                resources {
                  key
                  value {
                    value
                  }
                  isHtml
                }
                node_locale
              }
            }
        }
        allContentfulCaseStudy(filter: {node_locale: {regex: $languageRegEx}}) {
          nodes {
            id
            path
            title
            brandLogo {
              gatsbyImageData
            }
            atGlance {
              atGlance
            }
          }
        }
        caseStudyResourceSet: contentfulResourceSet(name: {eq: "caseStudies"}, node_locale: {regex: $languageRegEx}) {
          name
          resources {
              key
              value {
              value
              }
              isHtml
          }
          node_locale
        }
    }
`;